<template>
  <div class="horizontal--ad">
    <Adsense
      data-ad-client="ca-pub-7702415526221680"
      data-ad-slot="2205154412"
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  </div>
</template>

<script>
export default {
  name: "HorizontalAd"
};
</script>
